
.about__title {
    font-size: 2rem;
    color: white;
    margin-bottom: 16px;
  }
  
.research-box {
    background-color: rgba(255, 255, 255, 0.2);
    margin-bottom: 16px;
    border-radius: 5px;
    padding: 16px;
    cursor: pointer;
}
  
  .research-box h2 {
    color: #eee;
    font-size: 1.2rem;
    margin-bottom: 8px;
  }
  
  .research-box p {
    color: #eee;
    font-size: 1rem;
    margin-bottom: 12px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease;
  }
  
  .research-box.active p {
    max-height: 400px;
  }
  
  .works-consulted {
    color: #eee;
    font-size: 1rem;
    margin-top: 16px;
  }
  
  .works-consulted a {
    color: #eee;
    font-size: 1rem;
    margin-top: 6px;
    display: block;
  }

  .caret {
    float: right;
    transition: transform 0.3s ease-in-out;
    /* transform: rotate(-180deg); */
    transform: translateY(-12px);
  }
  
  .caret-rotate {
    /* transform: translateY(-50px);  */
    transform: rotate(180deg);
  }
  

@media screen and (max-width: 768px) {
    .caret {
        display: none;
      }
      
      .caret-rotate {
        transform: rotate(0);
        transform: translateY(-12px);
      }

      .research-box h2 {
        font-size: 1rem;
      }

      .research-box p {
        font-size: 0.9rem;
      }
      
}