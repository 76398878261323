.hero__main-text-container h1 {
    color: white;
    font-size: 6rem;
    letter-spacing: 5px;
    font-weight: 600;
}

.hero__container {
    padding-top: 6rem;
    justify-content: space-around;
}

.hero__subtext h4 {
    color: white;
    font-size: 1.5rem;
    font-weight: 400;
    letter-spacing: 1px;
}

.hero__main-text-container img {
    margin-right: -1.6rem;
    height: 50px;
    width: auto;
    margin-top: 1rem;
}

.hero__left-col__container {
    margin-right: 2rem;
}


.hero__btns-container {
    margin-top: 2rem;
}

.hero__btns-container a img {
    height: auto;
    margin-right: 0.75rem;
}
.iphone-container {
    width: 300px;
    height: 600px;
    -webkit-border-radius: 40px; /* Safari 3-4 */
    border-radius: 40px; /* Modern browsers */
    background: #999;
    overflow: hidden;
    position: relative;
    -webkit-overflow-scrolling: touch;
    
    scrollbar-width: none;
    -ms-overflow-style: none;
    border: 8px solid #000;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
}

  
  /* Additional classes to create the top speaker and home button */
  .iphone-container::before {
    content: "";
    position: absolute;
    top: 16px; /* Positioning from the top, adjust as needed */
    left: 50%;
    transform: translateX(-50%);
    width: 60px; /* Adjust as needed */
    height: 6px; /* Adjust as needed */
    background: #333; /* Color for the top speaker */
    border-radius: 3px;
  }
  

.feed-container {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100%;
    scroll-snap-type: y mandatory; /* y-axis snap */
    border-radius: 35px;
}

.feed-container video {
    display: block;
    /* height: 600px;
    width: 300px; */
    scroll-snap-align: start; /* Align to the start of the element */
    /* border-radius: 40px; */
}

.feed-container::-webkit-scrollbar {
    display: none; /* For Safari and Chrome */
}

  
/* .feed-container video {
display: block;
height: 100%;
width: auto;
scroll-snap-align: start;

} */

.feedSoundButton {
    margin-top: 1rem;
    padding: 0.25rem 1rem;
    background: none;
    border-radius: 999px;
    color: white;
    font-weight: 800;
    font-size: 0.75rem;
    border: 1px solid white;
}

.feedSidebar {
    width: 40px;
}

.feedNavbar {
    width: 250px
}


@media screen and (max-width: 768px) {

    .hero__main-text-container h1 {
        font-size: 4rem; 
    }

    .hero__left-col__container {
        align-items: center;
        margin-right: 0;
    }

    .hero__subtext h4 {
        font-size: 1rem;
        text-align: center;
    }

    .hero__main-text-container img {
        height: 25px; /* Adjusted image size */
        margin-right: -0.75rem;
        margin-top: 0.5rem;
    }

    .hero__btns-container a img {
        max-width: 150px; /* Added max-width for buttons */
    }

    .feed-container video {
        height: 500px;
    }
    .hero__container {
        flex-direction: column;
        align-items: center;
    }
    .iphone-container {
        width: 250px;
        height: 500px;
    }

    .hero__right-col__container {
        margin-top: 2rem;
    }
    .feedSidebar {
        width: 30px;
    }
    
    .feedNavbar {
        width: 200px
    }
    
}

