.iphone-container {
    width: 300px;
    height: 600px;
    border-radius: 40px;
    background: #000;
    overflow: hidden;
    position: relative;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    -ms-overflow-style: none;
    border: 8px solid #000;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  }
  
  .iphone-container::before {
    content: "";
    position: absolute;
    top: 16px;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 6px;
    background: #333;
    border-radius: 3px;
  }
  
  .feed-container {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100%;
    scroll-snap-type: y mandatory;
    border-radius: 35px;
  }
  
  .feed-container video {
    display: block;
    scroll-snap-align: start;
    height: 100%;
  }
  
  .feed-container::-webkit-scrollbar {
    display: none;
  }
  
  .feedSoundButton {
    margin-top: 1rem;
    padding: 0.25rem 1rem;
    background: none;
    border-radius: 999px;
    color: white;
    font-weight: 800;
    font-size: 0.75rem;
    border: 1px solid white;
  }
  
  .feedSidebar {
    width: 40px;
  }
  
  .feedNavbar {
    width: 250px;
  }
  