.footer-container {
    background: #202020;
    padding: 1rem 0;
}

.footer-content-container {
    width: 80%;
    align-self: center;
}

.footer__links-col {
    margin-right: 6rem;
}

.footer__links-col h1 {
    font-size: 1rem;
    color: white;
}

.footer__links-col a {
    font-size: 0.75rem;
    color: #fff;
    font-weight: 200;
    margin-top: 1rem;
}

footer__right-container a {
    font-size: 0.75rem;
    color: #fff;
    font-weight: 200;
    margin-top: 1rem;
}

.footer__right-container {
    justify-content: flex-end;
}

.footer-div-line {
    width: 80%;
    align-self: center;
    height: 0.5px;
    background: #fff;
    margin: 2rem 0 1rem 0;
}

.footer__bottom-container {
    width: 80%;
    align-self: center;
    margin-bottom: 2rem;
}

.footer__bottom-left-container p {
    font-size: 0.75rem;
    color: white;
}

.footer__icons-row img {
    height: 2rem;
    width: auto;
    margin-left: 0.5rem;
}