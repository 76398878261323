.about__left-col__container img {
    width: 300px;

}

.about__top-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.about__left-col__container {
    margin-right: 2rem;
}

/* .about__right-col__container {
    max-width: 50ch;
} */

.button-try-us-out {
    background: none;
    padding: 12px 16px;
    border: solid 1px white;
    border-radius: 999px;
    transition: all 0.2s ease;
    display: inline-block; /* Added to make sure the hover effect applies */
    margin: 1rem 0;
}

.button-try-us-out a {
    color: white;
    font-weight: 600;
    transition: all 0.2s ease;
    text-decoration: none; /* Optional, to remove underline */
}

.button-try-us-out:hover {
    border-color: #ffffff50;
}

.button-try-us-out:hover a {
    color: #ffffff50;
}

.paragraph__container {
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    /* padding-right: 2rem; */
    /* height: 400px; */
}

.feed-container::-webkit-scrollbar {
    display: none; /* For Safari and Chrome */
}



@media screen and (max-width: 768px) {
    .about__left-col__container {
        display: none;
    }
    .about__top-container {
        flex-direction: column;
        text-align: center;
    }
    .about__top-container p{
        font-size: 12;
    }

}


/* .dot-progress-bar {
    position: fixed;
    z-index: 1000;
    bottom: 10%;
    display: flex;
    flex-direction: row;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .dot-active,
  .dot-inactive {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin: 0 5px;
    background-color: #aaa;
    transition: background-color 0.3s ease;
  }
  
  .dot-active {
    background-color: #fff;
  }
   */