.section__margin {
  margin: 4rem 8rem;
}

.section__padding {
  padding: 6rem 12rem;
}

a {
  text-decoration: none;
  cursor: pointer;
}

body, #root {
  overflow-x: hidden;
  position: relative;
}


* {
  font-family: 'Montserrat', sans-serif;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

.fc {
  display: flex;
  flex-direction: column;
}

.fr {
  display: flex;
  flex-direction: row;
}

.ac {
  align-items: center;
}

.jc {
  justify-content: center;
}

.sb {
  justify-content: space-between;
}

.gradient__bg {
  background: linear-gradient(127deg, #029EB4 0%, #35A881 100%);
}

.custom-cursor {
  position: absolute;
  width: 30px; /* You can adjust the size of the cursor */
  height: 30px; /* You can adjust the size of the cursor */
  pointer-events: none;
  transition: all 0.2s ease;
  z-index: 10000;
  opacity: 0;
}

@media screen and (max-width: 768px) {
  .section__padding {
    padding: 2rem 4rem;
  }
}