.candid__navbar {
    position: sticky;
    top: 0;
    align-self: flex-start;
    z-index: 5;
    width: 100%;
}
.candid__navbar-content {
    height: 92px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 2rem;
    min-height: 75px;
}

.candid__navbar-menu {
    max-width: 850px;
}

.candid__navbar-menu h1 {
    font-size: 16px;
    font-weight: 500;
    transition: 250ms ease-in;
    padding: 1rem;
    color: white;
}

.candid__navbar-link {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.candid__navbar-link-line {
    height: 4px;
    background: var(--primary-blue);
    border: none;
    width: 40px;
    margin-top: -4px;
}


#candid__navbar-hamburger {
    display: block;
    position: relative;
    z-index: 2;

    user-select: none;

    appearance: none;
    border: none;
    outline: none;
    background: none;
    cursor: pointer;
}

#candid__navbar-hamburger span {
    display: block;
    width: 28px;
    height: 2px;
    margin-bottom: 5px;
    position: relative;
    
    background-color: white;
    border-radius: 6px;
    z-index: 2;

    transform-origin: 0 0;
    transition: 400ms;
}

#candid__navbar-hamburger:hover span:nth-child(2) {
    transform: translateX(10px);
    background-color: #ffffff75;
}

#candid__navbar-hamburger.is-active span:nth-child(1) {
    transform: translate(0px, -4px) rotate(45deg);
}

#candid__navbar-hamburger.is-active span:nth-child(2) {
    opacity: 0;
    transform: translateX(15px);
}
#candid__navbar-hamburger.is-active span:nth-child(3) {
    transform: translate(-2px, 2px) rotate(-45deg);
}


.candid__navbar-menu {
    display: none;
    justify-content: space-around;
}

.candid__navbar-menu a {
    color: black;
    
    text-decoration: none;
    transition: 0.4s;
    font-weight: 600;
    cursor: pointer;
    font-size: 20px;
}


#candid__navbar-overlay {
    background: linear-gradient(127deg, #029EB4 0%, #35A881 100%);
    visibility: hidden;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    text-align: end;
    padding: 2rem;
    position: absolute;
    right: 0;
    left: 0;
    top: 70px;
    min-width: 210px;
    box-shadow: 0px 0px 5px rgba(0,0, 0, 0.4);
    z-index: 1;

    
    transition: 750ms ease-in-out;
    transform: translateX(100%);
    opacity: 0;
}

#candid__navbar-overlay.is-active {
    transform: translateX(0);
    opacity: 100%;
    visibility: visible;
}

#candid__navbar-overlay a.is-active {
    transform: translateX(0);
    visibility: visible;
    transition: font-weight 100ms ease-in;
}

#candid__navbar-overlay span {
    visibility: hidden;
    height: 4px;
    width: 40px;
    margin-bottom: 5px;
    position: relative;
    
    background-color: var(--primary-blue);
    z-index: 2;
    transform-origin: center;
    transform: scaleX(0%);
    transition: 500ms ease-in;
}

#candid__navbar-overlay span.is-active {
    visibility: visible;
    transform: scaleX(100%);
    display: block;
    transition-delay: 750ms;
}

#candid__navbar-overlay a {
    display: flex;
    padding: 0.75rem;
    font-size: 16px;
    font-weight: 500;
    transition: 750ms ease-in;
    transform: translateX(200%);
    visibility: hidden;
    transition: 500ms ease-in-out;
    color: white;
}

#candid__navbar-overlay a:hover {
    font-weight: bold;
}

#candid__navbar-overlay a p {
    margin-right: 1rem;
    font-size: 16px;
    font-weight: bold;
}
.candid__navbar-line {
    height: 1px;
    background: var(--dashboard-line);
    border: none;
}

@media screen and (min-width: 1000px) {
    .candid__navbar-hamburger {
        display: none;
    }

    .candid__navbar-menu {
        display: flex;
    }
    
}
